import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby-link'
import axios from 'axios'

import { useInputChange } from '../hooks/useInputChange'
import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'

import './contact.sass'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = () => {
  const [input, handleInputChange] = useInputChange()
  const [error, setError] = useState('')
  const submitMessage = (e) => {
    e.preventDefault()
    const form = e.target
    const axiosConfig = {
      header: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
    const { name, email, message } = input
    if (name && email && message) {
      try {
        axios.post('/', encode({ 'form-name': 'contact', name, email, message }), axiosConfig)
        navigate(form.getAttribute('action'))
      } catch (error) {
        console.error('error posting form', error)
      }
    } else {
      setError('エラーは発生しました。もう一度フォームを確認してください。')
    }
  }
  return (
    <PageWrapper>
      <section className="contactPage">
        <SectionTitle title="Contact" subtitle="お問い合わせ" />
        <div className="small errorMessage">{error}</div>
        <form
          className="contactForm"
          onSubmit={submitMessage}
          action="/thanks/"
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
            Don’t fill this out: <input name="bot-field" onChange={handleInputChange} />
            </label>
          </p>
          <label>
            <div className="label">名前</div>
            <input type="text" className="textInput" name="name" onChange={handleInputChange} required/>
          </label>
          <label>
            <div className="label">メールアドレス</div>
            <input type="email" className="textInput" name="email" onChange={handleInputChange} required/>
          </label>
          <label>
            <div className="label">内容</div>
            <textarea className="textArea" name="message" onChange={handleInputChange} required></textarea>
          </label>
          <button type="submit" className="btn submitBtn jp-accent">送信</button>
        </form>
      </section>
    </PageWrapper>
  )
}
export default memo(Contact)

Contact.propTypes = {

}
Contact.defaultProps = {

}
